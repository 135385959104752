export const authActions = {
  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  LOGIN_EMAIL: "LOGIN_EMAIL",
  GOOGLE_ACCESS_TOKEN: "GOOGLE_ACCESS_TOKEN",
  GOOGLE_ID_TOKEN: "GOOGLE_ID_TOKEN",
  LOGIN_PASSWORD: "LOGIN_PASSWORD",
  SESSION_TOKEN: "SESSION_TOKEN",
  LOGIN_TYPE: "LOGIN_TYPE",
};
