import { combineReducers } from "redux";
import { common, login, userDetails, } from "./reducers";

const appReducer = combineReducers({
  UserCred: login,
  UserDetails: userDetails,
  Theme: common,
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    state = initialState;
  }

  return appReducer(state, action);
};

export default rootReducer;
