import { commonActions } from "../constants"

const initialState = {
    sidebarShow: true,
    asideShow: false,
    sidebarUnfoldable: false,
    theme: 'light',
}

export const common = (state = initialState, action) => {
    switch (action.type) {
        // case commonActions.SET:
        //     return { ...state, action.payload }
        case commonActions.sidebarShow:
            return { ...state, sidebarShow: action.payload }
        case commonActions.asideShow:
            return { ...state, asideShow: action.payload }
        case commonActions.sidebarUnfoldable:
            return { ...state, sidebarUnfoldable: action.payload }
        case commonActions.theme:
            return { ...state, theme: action.payload }
        default:
            return state
    }
}