import { userActions } from "../constants"

const initialState = {
    profileDetails: "",
    profileMe: "",
}

export const userDetails = (state = initialState, action) => {
    switch (action.type) {
        case userActions.USER_PROFILE_DETAILS:
            return {
                ...state,
                profileDetails: action.payload,
            }
        case userActions.USER_AVATAR:
            return {
                ...state,
                profileMe: action.payload,
            }
        default:
            return state
    }
}