import { authActions } from "../constants"

const initialState = {
    access_token: "",
    email: "",
    google_access_token: "",
    google_id_token: "",
    password: "",
    session_token: "",
    type: ""
}

export const login = (state = initialState, action) => {
    switch (action.type) {
        case authActions.ACCESS_TOKEN:
            return {
                ...state,
                access_token: action.payload,
            }
        case authActions.LOGIN_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case authActions.GOOGLE_ACCESS_TOKEN:
            return {
                ...state,
                google_access_token: action.payload,
            }
        case authActions.GOOGLE_ID_TOKEN:
            return {
                ...state,
                google_id_token: action.payload
            }
        case authActions.LOGIN_PASSWORD:
            return {
                ...state,
                password: action.payload
            }
        case authActions.SESSION_TOKEN:
            return {
                ...state,
                session_token: action.payload
            }
        case authActions.LOGIN_TYPE:
            return {
                ...state,
                type: action.payload
            }
        case authActions.LOGOUT_SUCCESS:
            return {
                ...state,
                access_token: "",
                email: "",
                google_access_token: "",
                google_id_token: "",
                password: "",
                session_token: "",
                type: ""
            }
        default:
            return state
    }
}